import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import {getMeetingRoomLabel} from '../../Utils/SelectOptionLabelUtil';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import ExportHelper from '../../TbaExportHelper';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {JStorage} from 'rev.sdk.js';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import * as LegacyUtil from '../../Utils/LegacyUtil';

const EXPORT_COLLECTIONS = ['product', 'Meeting', 'internal_exchange'];
const EXPORT_TITLE_DEFAULT = '活動總表匯出';
const EXPORT_FIELDS = [
  {key: 'data_type', name: '資料類型'},
  {key: 'date', name: '日期'},
  {key: 'time', name: '時間'},
  {key: 'name', name: '名稱'},
  {key: 'description', name: '說明'},
  {key: 'unit', name: '主協辦單位'},
  {key: 'organizer', name: '主辦人'},
  {key: 'attend_count', name: '出席人數'},
  {key: 'place', name: '場地'},
  {key: 'price', name: '費用'},
  {key: 'execution_note', name: '執行情形'},
  {key: 'note', name: '備註'},
];

function transformQuery(formData, metaData) {
  const {
    start_date,
    end_date,
    unit,
    organizer,
    course_labels,
    activity_labels,
    meeting_labels,
  } = formData;

  const query = {
    meeting: {},
    course: {},
    activity: {},
    intExc: {},
  };

  if (metaData.hasMeeting) {
    if (
      !!unit ||
      !!organizer ||
      !!start_date ||
      !!end_date ||
      (Array.isArray(meeting_labels) && meeting_labels.length > 0)
    ) {
      query['meeting'].$and = [];
    }

    if (!!unit) {
      query['meeting'].$and.push({organizer: {$regex: unit}});
    }

    if (!!organizer) {
      query['meeting'].$and.push({organizer_person: {$regex: organizer}});
    }

    if (Array.isArray(meeting_labels) && meeting_labels.length > 0) {
      query['meeting'].$and.push({MTNG_TYPE: {$in: meeting_labels}});
    }

    if (!!start_date) {
      query['meeting'].$and.push({EFF_DATE: {$gte: start_date}});
    }

    if (!!end_date) {
      let dateQueryIdx = query['meeting'].$and.findIndex(
        (q) => !!q['EFF_DATE'],
      );

      if (dateQueryIdx > -1) {
        query['meeting'].$and[dateQueryIdx]['EFF_DATE'] = {
          ...query['meeting'].$and[dateQueryIdx]['EFF_DATE'],
          $lte: end_date,
        };
      } else {
        query['meeting'].$and.push({EFF_DATE: {$lte: end_date}});
      }
    }

    query['meeting'].$and.push({archived: {$ne: true}});
    query['meeting'].$and.push({is_auto_created: {$ne: true}});
  }

  if (metaData.hasCourse) {
    query['course'].$and = [{archived: {$ne: true}}, {is_old: {$ne: true}}];

    if (!!unit) {
      query['course'].$and.push({unit: {$regex: unit}});
    }

    if (Array.isArray(course_labels) && course_labels.length > 0) {
      query['course'].$and.push({internal_label: {$in: course_labels}});
    }

    //product not have organizer field
    if (!!organizer) {
      query['course'].$and.push({organizer: {$exists: true}});
    }

    if (!!start_date) {
      query['course'].$and.push({'session.date': {$gte: start_date}});
    }

    if (!!end_date) {
      let sessionQueryIdx = query['course'].$and.findIndex(
        (q) => !!q['session.date'],
      );

      if (sessionQueryIdx > -1) {
        query['course'].$and[sessionQueryIdx]['session.date'] = {
          ...query['course'].$and[sessionQueryIdx]['session.date'],
          $lte: end_date,
        };
      } else {
        query['course'].$and.push({'session.date': {$lte: end_date}});
      }
    }
  }

  if (metaData.hasActivity) {
    query['activity'].$and = [{archived: {$ne: true}}, {is_old: {$ne: true}}];

    if (!!unit) {
      query['activity'].$and.push({unit: {$regex: unit}});
    }

    if (Array.isArray(activity_labels) && activity_labels.length > 0) {
      query['activity'].$and.push({internal_label: {$in: activity_labels}});
    }

    //product not have organizer field
    if (!!organizer) {
      query['activity'].$and.push({organizer: {$exists: true}});
    }

    if (!!start_date) {
      query['activity'].$and.push({'session.date': {$gte: start_date}});
    }

    if (!!end_date) {
      let sessionQueryIdx = query['activity'].$and.findIndex(
        (q) => !!q['session.date'],
      );

      if (sessionQueryIdx > -1) {
        query['activity'].$and[sessionQueryIdx]['session.date'] = {
          ...query['activity'].$and[sessionQueryIdx]['session.date'],
          $lte: end_date,
        };
      } else {
        query['activity'].$and.push({'session.date': {$lte: end_date}});
      }
    }
  }

  if (metaData.hasIntExc) {
    if (!!unit || !!organizer || !!start_date || !!end_date) {
      query['intExc'].$and = [];
    }

    if (!!unit) {
      query['intExc'].$and.push({organiser_unit: {$regex: unit}});
    }

    if (!!organizer) {
      query['intExc'].$and.push({organiser: {$regex: organizer}});
    }

    if (!!start_date) {
      query['intExc'].$and.push({exchange_start_date: {$gte: start_date}});
    }

    if (!!end_date) {
      let dateQueryIdx = query['intExc'].$and.findIndex(
        (q) => !!q['exchange_start_date'],
      );

      if (dateQueryIdx > -1) {
        query['intExc'].$and[dateQueryIdx]['exchange_start_date'] = {
          ...query['intExc'].$and[dateQueryIdx]['exchange_start_date'],
          $lte: end_date,
        };
      } else {
        query['intExc'].$and.push({exchange_start_date: {$lte: end_date}});
      }
    }
  }

  return query;
}

function transformProjection(formData, metaData) {
  const projection = {
    meeting: {},
    course: {},
    activity: {},
    intExc: {},
  };

  if (metaData.hasMeeting) {
    projection['meeting'].EFF_DATE = 1;
    projection['meeting'].START_TIME = 1;
    projection['meeting'].END_TIME = 1;
    projection['meeting'].MTNG_NAME = 1;
    projection['meeting'].DESCRIPTION = 1;
    projection['meeting'].organizer_person = 1;
    projection['meeting'].organizer = 1;
    projection['meeting'].users = 1;
    projection['meeting'].room = 1;
    projection['meeting'].room_note = 1;
    projection['meeting'].CEXECUTE = 1;
    projection['meeting'].NOTE = 1;
  }

  if (metaData.hasCourse) {
    projection['course'].session = 1;
    projection['course'].name = 1;
    projection['course'].price = 1;
    projection['course'].intro = 1;
    projection['course'].execution_note = 1;
    projection['course'].unit = 1;
  }

  if (metaData.hasActivity) {
    projection['activity'].session = 1;
    projection['activity'].name = 1;
    projection['activity'].price = 1;
    projection['activity'].intro = 1;
    projection['activity'].execution_note = 1;
    projection['activity'].unit = 1;
  }

  if (metaData.hasIntExc) {
    projection['intExc'].title = 1;
    projection['intExc'].exchange_start_date = 1;
    projection['intExc'].exchange_end_date = 1;
    projection['intExc'].exchange_location = 1;
    projection['intExc'].description = 1;
    projection['intExc'].execution = 1;
    projection['intExc'].note = 1;
  }

  return projection;
}

function generateExportFields(selectAllFields, fields) {
  return selectAllFields
    ? EXPORT_FIELDS
    : EXPORT_FIELDS.filter(
        (obj) => fields.findIndex((key) => key === obj.key) > -1,
      );
}

async function fetchRecords(formData, metaData) {
  const {incluDataTypes, query, projection} = metaData;

  let returnValue = [];

  let meetingResp = null;
  let courseResp = null;
  let activityResp = null;
  let intExcResp = null;

  if (incluDataTypes.hasMeeting) {
    meetingResp = await JStorage.fetchDocuments(
      'Meeting',
      query.meeting,
      ['EFF_DATE'],
      {offset: 0, limit: 1000},
      projection.meeting,
    );

    if (meetingResp.total > 0) {
      returnValue = [
        ...returnValue,
        ...meetingResp.results.map((record) => ({
          ...record,
          EFF_DATE: record.EFF_DATE?.split('T')[0],
          START_TIME: record.START_TIME?.slice(0, 5),
          END_TIME: record.END_TIME?.slice(0, 5),
          data_type: '會議',
        })),
      ];
    }

    if (meetingResp.total > 1000) {
      message.info('會議超過上限1000筆, 超過部分將不會顯示在excel列表中！');
    }
  }

  if (incluDataTypes.hasCourse) {
    courseResp = await JStorage.fetchDocuments(
      'product',
      query.course,
      ['session.date'],
      {offset: 0, limit: 1000},
      projection.course,
    );

    if (courseResp.total > 0) {
      returnValue = [
        ...returnValue,
        ...courseResp.results.map((record) => ({...record, data_type: '課程'})),
      ];
    }

    if (courseResp.total > 1000) {
      message.info('課程超過上限1000筆, 超過部分將不會顯示在excel列表中！');
    }
  }

  if (incluDataTypes.hasActivity) {
    activityResp = await JStorage.fetchDocuments(
      'product',
      query.activity,
      ['session.date'],
      {offset: 0, limit: 1000},
      projection.activity,
    );

    if (activityResp.total > 0) {
      returnValue = [
        ...returnValue,
        ...activityResp.results.map((record) => ({
          ...record,
          data_type: '活動',
        })),
      ];
    }

    if (activityResp.total > 1000) {
      message.info('活動超過上限1000筆, 超過部分將不會顯示在excel列表中！');
    }
  }

  if (incluDataTypes.hasIntExc) {
    intExcResp = await JStorage.fetchDocuments(
      'international_exchange',
      query.intExc,
      ['exchange_start_date'],
      {offset: 0, limit: 1000},
      projection.intExc,
    );

    if (intExcResp.total > 0) {
      returnValue = [
        ...returnValue,
        ...intExcResp.results.map((record) => ({
          ...record,
          data_type: '國際交流',
        })),
      ];
    }

    if (intExcResp.total > 1000) {
      message.info('國際交流超過上限1000筆, 超過部分將不會顯示在excel列表中！');
    }
  }

  return returnValue;
}

function AdminExecutionExport(props) {
  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            dataTypes: {
              type: 'array',
              title: '資料範圍',
              uniqueItems: true,
              default: ['課程', '活動', '會議', '國際交流'],
              items: {
                type: 'string',
                enum: ['課程', '活動', '會議', '國際交流'],
              },
            },
            start_date: {
              type: 'string',
              title: '日期區間（起）',
              format: 'date',
            },
            end_date: {
              type: ['string'],
              title: '日期區間（迄）',
              format: 'date',
            },
            unit: {
              type: 'string',
              title: '主辦單位',
            },
            organizer: {
              type: 'string',
              title: '主辦人',
            },
            course_labels: {
              type: 'array',
              title: '公會內部分類：課程',
              uniqueItems: true,
              default: ['在職進修', '採認進修', '專錄影片'],
              items: {
                type: 'string',
                enum: ['在職進修', '採認進修', '專錄影片'],
              },
            },
            activity_labels: {
              type: 'array',
              title: '公會內部分類：活動',
              uniqueItems: true,
              default: [
                '座談研討會',
                '午間小品',
                '拜會',
                '接待',
                '律師節活動',
                '外部活動',
                '外租場地',
                '其他',
              ],
              items: {
                type: 'string',
                enum: [
                  '座談研討會',
                  '午間小品',
                  '拜會',
                  '接待',
                  '律師節活動',
                  '外部活動',
                  '外租場地',
                  '其他',
                ],
              },
            },
            meeting_labels: {
              type: 'array',
              title: '會議類別',
              uniqueItems: true,
              default: ['INNER', 'OUTER', 'GSM', 'BM', 'SV', 'MD', 'BOSM'],
              items: {
                type: 'string',
                enum: ['INNER', 'OUTER', 'GSM', 'BM', 'SV', 'MD', 'BOSM'],
                enumNames: [
                  '內部',
                  '外部',
                  '會員大會',
                  '理事會',
                  '監事會',
                  '常務理事會議',
                  '理監事聯席會議',
                ],
              },
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      type: 'array',
                      title: '匯出欄位選擇',
                      items: {
                        type: 'string',
                        enum: EXPORT_FIELDS.map((field) => field.key),
                        enumNames: EXPORT_FIELDS.map((field) => field.name),
                      },
                      uniqueItems: true,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          title: {
            'ui:help': '包含活動、課程、會議、國際交流、專案',
          },
          start_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
          end_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
          organizer: {
            'ui:widget': 'admin-select-user-custom-widget',
          },
        }}
        onSubmit={(formData) => {
          const {dataTypes: dts, selectAllFields, fields} = formData;

          const incluDataTypes = {
            hasMeeting: dts.findIndex((c) => c === '會議') > -1,
            hasCourse: dts.findIndex((c) => c === '課程') > -1,
            hasActivity: dts.findIndex((c) => c === '活動') > -1,
            hasIntExc: dts.findIndex((c) => c === '國際交流') > -1,
          };

          let query = transformQuery(formData, {...incluDataTypes});
          let projection = transformProjection(formData, {...incluDataTypes});
          let selectedAllFields = generateExportFields(selectAllFields, fields);

          let excelProps = {
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            fetchRecords: () =>
              fetchRecords(formData, {
                incluDataTypes,
                query,
                projection,
              }),
            recordsToAoa: async (records) => {
              const helper = new ExportHelper({
                signFormProductIds: records
                  .filter(
                    (r) => r.data_type === '課程' || r.data_type === '活動',
                  )
                  .map((r) => r.id),
                committeeIds: records
                  .filter((r) => r.data_type === '會議')
                  .map((r) => r.committee),
                intExchIds: records
                  .filter((r) => r.data_type === '會議')
                  .map((r) => r.international_exchange),
                moraleIds: (() => {
                  const ids = [];
                  const morale_list = records
                    .filter((r) => r.data_type === '會議')
                    .map((r) => r.morale_list);

                  for (const arrayMorale of morale_list) {
                    if (Array.isArray(arrayMorale) && arrayMorale.length > 0) {
                      for (const itemMorale of arrayMorale) {
                        ids.push(itemMorale.morale);
                      }
                    }
                  }

                  return ids;
                })(),
              });

              await helper.fetchAll();

              return [
                [...selectedAllFields.map((f) => '')],
                [...selectedAllFields.map((f) => f.name)],
                ...records.map((r, index) => {
                  const returnValues = selectedAllFields.map((f) => {
                    if (r.data_type === '會議') {
                      if (f.key === 'data_type') {
                        return '會議';
                      } else if (f.key === 'date') {
                        return r.EFF_DATE || '';
                      } else if (f.key === 'time') {
                        return `${r.START_TIME || '未設定'}-${
                          r.END_TIME || '未設定'
                        }`;
                      } else if (f.key === 'name') {
                        return r.MTNG_NAME || '';
                      } else if (f.key === 'description') {
                        return r.DESCRIPTION || '';
                      } else if (f.key === 'unit') {
                        return r.organizer || '';
                      } else if (f.key === 'organizer') {
                        return helper.getUser(r.organizer_person).name || '';
                      } else if (f.key === 'attend_count') {
                        return Array.isArray(r.users) ? r.users.length : 0;
                      } else if (f.key === 'place') {
                        return (
                          getMeetingRoomLabel(r['room'], r['room_note']) || ''
                        );
                      } else if (f.key === 'price') {
                        return '';
                      } else if (f.key === 'execution_note') {
                        return r.CEXECUTE || '';
                      } else if (f.key === 'note') {
                        return r.NOTE || '';
                      }
                    }

                    if (r.data_type === '課程') {
                      const session = r.session || {};
                      const people = session.people || [];
                      const signForm = helper.getLatestSignForm(r.id);

                      if (f.key === 'data_type') {
                        return '課程';
                      } else if (f.key === 'date') {
                        return session.date || '';
                      } else if (f.key === 'time') {
                        return `${session.start_time || ''}-${
                          session.end_time || ''
                        }`;
                      } else if (f.key === 'name') {
                        return r.name || '';
                      } else if (f.key === 'description') {
                        return r.intro || '';
                      } else if (f.key === 'unit') {
                        return r.unit || '';
                      } else if (f.key === 'organizer') {
                        //product doesn't have organizer field
                        return '';
                      } else if (f.key === 'attend_count') {
                        const attendMembers = signForm
                          ? (signForm.members || []).filter((m) => m.is_attend)
                          : [];

                        const attendOnlineMembers = signForm
                          ? (signForm.online_members || []).filter(
                              (m) => m.is_attend,
                            )
                          : [];

                        const attendCount =
                          attendMembers.length + attendOnlineMembers.length;

                        return `${attendCount}`;
                      } else if (f.key === 'place') {
                        return `${
                          session.place || ''
                        } ${LegacyUtil.getProductMeetingRoomsLabel(r)}`;
                      } else if (f.key === 'price') {
                        return r.price || 0;
                      } else if (f.key === 'execution_note') {
                        return r.execution_note || '';
                      } else if (f.key === 'note') {
                        return session.note || '';
                      }
                    }

                    if (r.data_type === '活動') {
                      const session = r.session || {};
                      const people = session.people || [];
                      const signForm = helper.getLatestSignForm(r.id);

                      if (f.key === 'data_type') {
                        return '活動';
                      } else if (f.key === 'date') {
                        return session.date || '';
                      } else if (f.key === 'time') {
                        return `${session.start_time || ''}-${
                          session.end_time || ''
                        }`;
                      } else if (f.key === 'name') {
                        return r.name || '';
                      } else if (f.key === 'description') {
                        return r.intro || '';
                      } else if (f.key === 'unit') {
                        return r.unit || '';
                      } else if (f.key === 'organizer') {
                        //product doesn't have organizer field
                        return '';
                      } else if (f.key === 'attend_count') {
                        const attendMembers = signForm
                          ? (signForm.members || []).filter((m) => m.is_attend)
                          : [];

                        const attendOnlineMembers = signForm
                          ? (signForm.online_members || []).filter(
                              (m) => m.is_attend,
                            )
                          : [];

                        const attendCount =
                          attendMembers.length + attendOnlineMembers.length;

                        return `${attendCount}`;
                      } else if (f.key === 'place') {
                        return `${
                          session.place || ''
                        } ${LegacyUtil.getProductMeetingRoomsLabel(r)}`;
                      } else if (f.key === 'price') {
                        return r.price || 0;
                      } else if (f.key === 'execution_note') {
                        return r.execution_note || '';
                      } else if (f.key === 'note') {
                        return session.note || '';
                      }
                    }

                    if (r.data_type === '國際交流') {
                      if (f.key === 'data_type') {
                        return '國際交流';
                      } else if (f.key === 'date') {
                        return `${r.exchange_start_date || '開始日期未設定'}-${
                          r.exchange_end_date || '結束日期未設定'
                        }`;
                      } else if (f.key === 'time') {
                        return `${r.exchange_start_time || '開始時間未設定'}-${
                          r.exchange_end_time || '結束時間未設定'
                        }`;
                      } else if (f.key === 'name') {
                        return r.title || '';
                      } else if (f.key === 'description') {
                        return r.description || '';
                      } else if (f.key === 'unit') {
                        return r.organiser_unit || '';
                      } else if (f.key === 'organizer') {
                        return r.organiser || '';
                      } else if (f.key === 'attend_count') {
                        return '';
                      } else if (f.key === 'place') {
                        return r.exchange_location || '';
                      } else if (f.key === 'price') {
                        return '';
                      } else if (f.key === 'execution_note') {
                        return r.execution || '';
                      } else if (f.key === 'note') {
                        return r.note || '';
                      }
                    }

                    return r[f.key];
                  });

                  return returnValues;
                }),
              ];
            },
            updateWorkSheet: (ws) => {
              const cells = Object.keys(ws);

              //title
              const title_columns = cells.filter(
                (cell) => cell.length === 2 && cell.indexOf('1') > -1,
              );

              ws['!merges'] = [
                {s: {r: 0, c: 0}, e: {r: 0, c: title_columns.length - 1}},
              ];
              const titles = [...title_columns];

              for (let i = 0; i < titles.length; i++) {
                const cell = titles[i];

                if (i === 0) {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else if (i + 1 === titles.length) {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //header
              const header_columns = cells.filter(
                (cell) => cell.length === 2 && cell.indexOf('2') > -1,
              );

              const headers = [...header_columns];

              for (let i = 0; i < headers.length; i++) {
                const header = headers[i];

                ws[header].s = {
                  sz: 10,
                  bold: true,
                  alignment: {
                    horizontal: 'center',
                  },
                  top: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  bottom: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  left: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  right: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                };
              }

              //cell style

              const excludeCells = [
                '!cols',
                '!merges',
                '!ref',
                ...titles,
                ...headers,
              ];

              for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];

                if (!excludeCells.includes(cell)) {
                  if (cell.indexOf('A') > -1 || cell.indexOf('B') > -1) {
                    ws[cell].s = {
                      ...ws[cell].s,
                      sz: 10,
                      alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                      },
                      top: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      bottom: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      left: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      right: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                    };
                  } else {
                    ws[cell].s = {
                      ...ws[cell].s,
                      sz: 10,
                      alignment: {
                        wrapText: true,
                        vertical: 'center',
                      },
                      top: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      bottom: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      left: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                      right: {
                        style: 'thin',
                        color: {rgb: '000000'},
                      },
                    };
                  }
                }
              }

              //col width
              ws['!cols'] = [
                {wpx: 80},
                {
                  wpx: 136,
                },
                {
                  wpx: 108,
                },
                {wpx: 163},
                {wpx: 190},
                {wpx: 136},
                {wpx: 81},
                {wpx: 81},
                {wpx: 136},
                {wpx: 81},
                {wpx: 163},
                {wpx: 163},
              ];

              //row height
              ws['!rows'] = [{hpx: 46}];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminExecutionExport;
