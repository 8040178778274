import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';

async function transformQuery(formData) {
  const query = {is_old: {$ne: true}};
  const {first_years, product} = formData;

  if (!!first_years && Array.isArray(first_years) && first_years.length > 0) {
    query.first_year = {$in: first_years};
  }

  if (!!product) {
    const p = await JStorage.fetchOneDocument('product', {
      _id: {$oid: product},
    });

    if (
      p.type === 'n_in_1' &&
      p.n_in_1_products &&
      Array.isArray(p.n_in_1_products) &&
      p.n_in_1_products.length > 0
    ) {
      query.products = {$in: [product, ...p.n_in_1_products]};
    } else {
      query.products = {$in: [product]};
    }
  }

  return query;
}

function AdminStudyHoursExport(props) {
  const [products] = useOutlet('products');

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={null}
        schema={{
          title: '',
          type: 'object',
          required: ['title', 'first_years'],
          properties: {
            title: {type: 'string', title: '報表名稱', default: '課程點數統計'},
            first_years: {
              type: 'array',
              title: '年度',
              uniqueItems: true,
              default: [new Date().getFullYear() - 1911],
              items: {
                type: 'integer',
                enum: [110, 111, 112, 113],
              },
            },
            product: {
              type: 'string',
              title: '課程名稱',
            },
          },
        }}
        uiSchema={{
          product: {
            'ui:widget': 'admin-select-product-widget',
          },
        }}
        onSubmit={async (formData) => {
          const query = await transformQuery(formData);
          const projection = {};

          const excelProps = {
            collection: 'study_hours',
            filename: `${formData.title || '點數統計'}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              let users = [];
              let companies = [];

              users = await JStorage.fetchAllDocuments(
                'user_profile',
                {
                  owner: {$in: records.map((r) => r.user_id)},
                },
                null,
                {owner: 1, company_current: 1, name: 1, id_card_number: 1},
              );

              if (users.filter((u) => !!u.company_current).length > 0) {
                companies = await JStorage.fetchAllDocuments('Company', {
                  _id: {
                    $in: users
                      .filter((u) => !!u.company_current)
                      .map((u) => ({$oid: u.company_current})),
                  },
                });
              }

              const targetProduct = formData.product
                ? await JStorage.fetchOneDocument('product', {
                    _id: {$oid: formData.product},
                  })
                : null;

              const hasNIn1Products =
                targetProduct &&
                targetProduct.n_in_1_products &&
                Array.isArray(targetProduct.n_in_1_products) &&
                targetProduct.n_in_1_products.length > 0;

              const nIn1ProductIds = hasNIn1Products
                ? targetProduct.n_in_1_products
                : null;

              const nIn1Products = hasNIn1Products
                ? products.filter((p) => nIn1ProductIds.includes(p.id))
                : null;

              let nextRecords = records
                .sort((a, b) => a.first_year > b.first_year)
                .map((r) => {
                  //user
                  const targetUser = users.find((u) => u.owner === r.user_id);
                  const name = targetUser ? targetUser.name : '';
                  const id_card_number = targetUser
                    ? targetUser.id_card_number
                    : '';
                  const targetCompany =
                    targetUser && targetUser.company_current
                      ? companies.find(
                          (c) => c.id === targetUser.company_current,
                        )
                      : null;
                  const company = targetCompany ? targetCompany.CONAME : '';

                  //hours
                  let hours = 0;

                  if (
                    targetProduct &&
                    targetProduct.type === 'n_in_1' &&
                    hasNIn1Products
                  ) {
                    const productIds = r.products.filter((id) =>
                      nIn1ProductIds.includes(id),
                    );

                    const selectedProducts = nIn1Products
                      .filter((product) => productIds.includes(product.id))
                      .map((product) => product.tba_hours);

                    hours = selectedProducts
                      ? selectedProducts.reduce((acc, cur) => acc + cur, 0)
                      : 0;
                  } else if (
                    targetProduct &&
                    targetProduct.type === 'default'
                  ) {
                    hours = targetProduct.tba_hours;
                  } else {
                    hours = r.hours;
                  }

                  return [r.first_year, id_card_number, name, company, hours];
                });

              let productText = targetProduct
                ? `${targetProduct.name} (進修時數：${targetProduct.tba_hours} 小時)`
                : '';

              let lengthText = nextRecords.length;
              let totalText = nextRecords.reduce(
                (acc, cur) => acc + cur[cur.length - 1],
                0,
              );

              return [
                ['進修時數', '', '', '', ''],
                [productText, '', '', '', ''],
                ['年度', '身分證字號', '姓名', '現在事務所', '時數'],
                ...nextRecords,
                ['', '合計', `共 ${lengthText} 筆`, '', `共 ${totalText} 小時`],
              ];
            },
            updateWorkSheet: (ws) => {
              ws['!merges'] = [
                // s = start, r = row, c = col, e = end
                {s: {r: 0, c: 0}, e: {r: 0, c: 4}},
                {s: {r: 1, c: 0}, e: {r: 1, c: 4}},
              ];

              ws['!cols'] = [
                {wpx: 60},
                {wpx: 100},
                {wpx: 80},
                {wpx: 150},
                {wpx: 100},
              ];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminStudyHoursExport;
